<template>
  <b-card>
    <div class="my-1 mb-2 d-flex flex-wrap justify-content-between pl-1">
      <div class="d-flex align-items-center mt-50">
        <a class=" mr-50" @click="$router.go(-1)">
          <feather-icon icon="ChevronLeftIcon" size="20" />
        </a>
        <h3 class="m-0 font-weight-600">
          {{ appTitle || $t('rooms.title') }}
        </h3>
      </div>
      <b-button
        v-if="isStaff || canAdd"
        v-b-modal.modal-create-rooms
        class="mr-1 mt-50"
        variant="outline-primary"
      >
        {{ $t('rooms.create') }}
      </b-button>
    </div>
    <component
      :is="containerComponent"
      :placeholder-message="$t('available.message', { itemName: $t('rooms.title') })"
      :items="rooms"
      :has-more="itemss.length < total"
      :total="total"
      :loading="loading"
      :loading-next-page="isLoadingNextPage"
      :per-page="perPage"
      :placeholder="placeholder"
      @edited="handleEdited"
      @load-next="handleLoadNextPage"
    >
      <template #default="{ item }">
        <rooms-section :room="item" @edit-room="openEditModal" />
      </template>
    </component>
    <b-modal
      id="modal-create-rooms"
      :title="$t('rooms.create')"
      centered
      size="lg"
      hide-footer
    >
      <b-form @submit.prevent="handleCreateItem">
        <b-form-group>
          <label for="name">{{ $t('rooms.name') }}</label>
          <b-form-input
            id="name"
            v-model="formInput.name"
            type="text"
            :placeholder="$t('form-create-item.name-placeholder')"
          />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="description">{{ $t('form-create-item.description') }}</label>
          <quill-editor v-model="formInput.description" />
        </b-form-group>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label for="typeEvent">{{ $t('rooms.modality') }}</label>
              <v-select
                id="typeEvent"
                v-model="selectedOption"
                :clearable="false"
                :options="typeEventsOptions"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="selectedOption !== $t('rooms.on-site')"
            cols="12"
            md="6"
          >
            <b-form-group>
              <label for="type">{{ $t('people.type') }}:</label>
              <v-select
                id="type"
                v-model="selectedType"
                :clearable="false"
                :options="typeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              v-if="selectedOption !== $t('rooms.on-site')"
            >
              <label for="app">App</label>
              <v-select
                id="app"
                v-model="selectedApp"
                :clearable="false"
                :options="appsOptions"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="selectedOption !== $t('rooms.on-site')" cols="12">
            <b-form-group>
              <label for="name">{{ $t('rooms.link') }}</label>
              <b-form-input
                id="name"
                v-model="formInput.startURL"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col class="12" md="12">
            <!-- Locations -->
            <b-form-group
              v-if="selectedOption !== $t('rooms.online')"
              :label="$t('organizations.edit.locations.label')"
              label-for="profile-location"
            >
              <places-map-select
                v-model="locations"
                :placeholder="$t('organizations.edit.locations.placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-form-group :label="$t('form-create-item.image')" label-for="logo" class="d-inline-block w-100">
              <file-preview
                v-if="imageSrc"
                :src="getImageResource(imageSrc)"
                :placeholder-image="avatarPlaceholder"
                mime-type="image"
                cancelable
                @cancel="imageSrc = null"
              />
              <file-upload
                v-model="itemImage"
                type="image"
                cancelable
                :placeholder="$t('form-create-item.image-button')"
                :drop-placeholder="$t('form-create-item.drop-placeholder')"
                @cancel="itemImage = null"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="float-right mb-50">
          <!-- Missing to implement BE -->
          <b-button
            variant="primary"
            class="float-right"
            :disabled="isSaving"
            type="submit"
          >
            <span
              v-if="isSaving"
              class="d-flex "
            >
              <b-spinner
                class="mr-1"
                small
                variant="white"
                label="Loading..."
              />
              {{ $t('form.actions.save') }}
            </span>
            <span v-else>{{ $t('form.actions.save') }}</span>
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <edit-room-modal
      v-if="isEditModalOpened"
      v-model="isEditModalOpened"
      :room="room"
      @edited="handleEdited"
    />
  </b-card>
</template>

<script>
import ItemsListMixin from '@core/components/containers/mixins/ItemsListMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import Placeholder from '@/assets/images/placeholders/light/rooms.svg';
import {
  ROOMS_STORE_MODULE_NAME, ROOMS_GETTERS,
} from '@/views/apps/rooms/constants/rooms-store-constants';
import roomsStoreModule from '@/views/apps/rooms/store/roomsStoreModule';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import FileTypes from '@/@core/constants/FileTypes';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';
import Service from '@/config/service-identifiers';
import { checkPermissions } from '@/@core/utils/roles-utils';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import EditRoomModal from '@/views/apps/rooms/components/EditRoomModal.vue';
import RoomsSection from './RoomsSection.vue';

export default {
  name: 'RoomsList',
  components: {
    PlacesMapSelect,
    RoomsSection,
    vSelect,
    quillEditor,
    EditRoomModal,
    FileUpload,
    FilePreview,
  },
  mixins: [ItemsListMixin, ToastNotificationsMixin],
  props: {
    perPage: {
      type: Number,
      default: 8,
    },
    small: Boolean,
  },
  data() {
    return {
      formInput: {
        name: null, description: '', modality: null, app: null, type: null,
      },
      itemImage: null,
      imageSrc: null,
      locations: [],
      isSaving: false,
      selectedOption: this.$t('rooms.online'),
      selectedApp: 'Conferencing',
      selectedType: 'Meeting',
      questionsToShow: [],
      room: [],
      isEditModalOpened: false,
      appRoom: [
        'Bluejeans',
        'Nectios Classroom',
        'Zoom.us',
        'Jisti',
      ],

    };
  },
  computed: {
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
    app() {
      return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === 19);
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'room',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    FileTypes() {
      return FileTypes;
    },
    typeEventsOptions() {
      return [this.$t('rooms.online'), this.$t('rooms.on-site'), this.$t('rooms.hybrid')];
    },
    appsOptions() {
      return this.appRoom;
    },
    typeOptions() {
      return ['Meeting', 'Webinar'];
    },
    placeholder() {
      return Placeholder;
    },
    total() {
      return this.$store.getters[ROOMS_GETTERS.total];
    },
    isAuditorium() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].mainRoom;
    },
    rooms() {
      return this.itemss.filter(({ isMain }) => isMain === false).reduce((acc, current) => {
        const x = acc.findIndex((item) => item.key === current.key);
        if (x === -1) {
          return acc.concat([current]);
        }
        acc[x] = current;
        return acc;
      }, []);
    },
    itemsData() {
      return this.$store.getters.rooms;
    },
    itemss() {
      if (!this.itemsData || this.itemsData.length === 0 || !this.itemsData.unpaginated) {
        return [];
      }
      return this.itemsData.unpaginated;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule(ROOMS_STORE_MODULE_NAME)) {
      this.$store.registerModule(ROOMS_STORE_MODULE_NAME, roomsStoreModule);
    }
  },
  async created() {
    this.updateBreadcrumbs();
    await this.getApp();
    this.selectQuestions();
    await this.fetchData();
  },
  methods: {
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: 'rooms.breadcrumb-text.rooms-list',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    translate(string) {
      return translateTranslationTable(this.currentLocale, string);
    },
    selectQuestions() {
      this.questionsToShow = [];
      this.isLoading = true;
      for (const row of this.appRoom) {
        this.questionsToShow.push({ name: this.translate(row.name), key: row.key });
      }
    },
    async getApp() {
      const response = await this.$store.$service[Service.BackendClient].get('addons/rooms/installed', {
        params: { communitySlug: this.communitySlug },
      });
      this.appRoom[0] = 'Conferencing';
      if (response.data) {
        for (const row of response.data) {
          this.appRoom.push(row.name.en);
        }
      }
    },
    modality() {
      switch (this.selectedOption) {
        case this.typeEventsOptions[1]:
          this.formInput.modality = 'physical';
          return;
        case this.typeEventsOptions[2]:
          this.formInput.modality = 'hybrid';
          return;
        case this.typeEventsOptions[0]:
          this.formInput.modality = 'online';
      }
    },
    appss() {
      switch (this.selectedApp) {
        case 'Conferencing':
          this.formInput.app = 'jitsi';
          return;
        case 'Zoom.us':
          this.formInput.app = 'zoom';
          return;
        case 'Nectios Classroom':
          this.formInput.app = 'classroom';
          return;
        case 'Bluejeans':
          this.formInput.app = 'bluejeans';
          return;
        case 'Jisti':
          this.formInput.app = 'jitsi';
      }
    },
    type() {
      switch (this.selectedType) {
        case this.typeOptions[0]:
          this.formInput.type = 'meeting';
          return;
        case this.typeOptions[1]:
          this.formInput.type = 'webinar';
      }
    },
    resetInputs() {
      this.formInput = {};
      this.itemImage = null;
      this.imageSrc = null;
    },
    async handleCreateItem() {
      // Name is required
      if (!this.formInput.name) {
        this.formError = false;
        return;
      }
      try {
        this.modality();
        this.appss();
        this.type();
        this.isSaving = true;
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'rooms',
            requestConfig: {
              name: this.formInput.name,
              description: this.formInput.description,
              modality: this.formInput.modality,
              locations: this.locations != null && this.locations.length > 0 ? this.locations : null,
              app: this.formInput.app != '' ? this.formInput.app : null,
              type: this.formInput.type,
              startURL: this.formInput.startURL,
            },
          },
          file: this.itemImage,
        });
        this.resetInputs();
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
        this.selectedOption = this.$t('rooms.online'),
        this.selectedApp = 'Conferencing';
        this.selectedType = 'Meeting';
        this.notifySuccess(this.$t('success-message.general-success-create'));
        this.closeModal();
        this.isSaving = false;
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
        this.closeModal();
        this.isSaving = false;
      }
    },
    closeModal() {
      this.$bvModal.hide('modal-create-rooms');
    },
    async loadPage({}) {
      return '';
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'rooms',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          orderByDate: -1,
        },
      });
    },
    handleEdited() {
      setTimeout(() => {
        this.fetchData();
      }, 2000);
      this.isEditModalOpened = false;
    },
    openEditModal(room) {
      console.log(room)
      this.room = room;
      this.isEditModalOpened = true;
    },
  },
};
</script>
